.item-detail-section {
  background-color: #778094;
  table-layout: fixed;
  width: 50%;
  margin: 3% auto;
  border-radius: 10px;
  padding-top: 30px;
  padding-bottom: 30px;
}

.item-detail-section h2 {
  text-align: center;
  font-family: sans-serif;
}

.item-detail-section img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.item-detail-dialog-table {
  table-layout: fixed;
  margin: auto;
  font-family: sans-serif;
  border-spacing: 25px;
}

.item-detail-dialog td.item-detail-table-stat-name {
  text-align: right;
  word-wrap: break-word;
  width: 50%;
  padding-left: 5px;
  padding-right: 5px;
}