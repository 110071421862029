.base-btn {
  background-color: #604d93;
  font-size: 20px;
  color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  border: none;
}

.base-btn:active {
  background-color: #7d6daa;
}