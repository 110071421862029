.tf-table table {
  color: #ffffff;
  width: 100%;
  margin: auto;
  border-collapse: collapse;
  border-spacing: 0;
  border-radius: 15px;
  overflow: hidden;
}

.tf-table th {
  text-align: left;
}

.tf-table tr {
  font-size: 24px;
  background-image: linear-gradient(#4b5772, 85%, #313849);
  width: 100%;
  height: 3em;
}

.tf-table .first-column {
  padding-left: 2em;
}

.tf-table td.first-column {
  font-weight: bolder;
}
