#motd {
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

#motd-text {
  width: 50%;
  height: 50%;
  text-align: center;
  font-family: sans-serif;
  font-size: 30px;
}