#page-header {
  display: flex;
  justify-content: space-between;
  min-height: 100px;
  background-color: #475168;
  font-family: sans-serif;
  font-weight: bolder;
  font-size: 80px;
  padding: 10px;
}

#wordmark {
  color: #ffffff;
  float: left
}