.nav-header {
  min-width: 100px;
  min-height: 10px;
  max-height: 50px;
  background-color: #7A9CCF;
  color: #ffffff;
  font-size: 20px;
  font-family: sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  background-image: linear-gradient(#7A9CCF, 85%, #104697);
}

.nav-item {
  min-width: 100px;
  min-height: 10px;
  max-height: 50px;
  background-color: #CDDEF8;
  color: #496DA4;
  font-size: 20px;
  font-family: sans-serif;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 5px;
  background-image: linear-gradient(#CDDEF8, 85%, #6e94c9);
}