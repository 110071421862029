html {
  height: 100%;
}

body {
  margin: 0 auto;
  min-height: 100vh;
}

.app-container {
  height: 100%;
}

#nav {
  background-color: gray;
  width: 25%;
  height: 100%;
  float: left;
}

#nav nav {
  height: 100vh;
}

#main-pane {
  background-color: #2c2f33;
  width: 75%;
  height: 100%;
  float: left;
}